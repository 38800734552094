import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { AdminDatabaseComponent } from './admin/database/admin-database.component';

const routes: Routes = [
  { path: '', redirectTo: '/admin/czechme', pathMatch: 'full'},
  { path: 'admin', redirectTo: '/admin/czechme', pathMatch: 'full'},
  { path: 'admin/:module', canActivate: [AuthGuard], component: AdminDatabaseComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
