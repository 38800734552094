import { Component, Input, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JSONEditor } from '@json-editor/json-editor';
import JSONEditorCs from '../../../assets/i18n/json-editor/cs.json'
import * as _ from "lodash";
import {MatSnackBar} from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';

declare var Jodit;
declare var window;

@Component({
  selector: 'app-data-manager',
  templateUrl: './data-manager.component.html',
  styleUrls: ['./data-manager.component.css']
})
export class DataManagerComponent implements OnInit {
  constructor(private http: HttpClient, private snackBar: MatSnackBar) {
    window.Jodit = Jodit;
    JSONEditor.defaults.languages.cs = JSONEditorCs;
    JSONEditor.defaults.language = "cs";
  }
  private element;
  private editor;
  @Input() endpointSchemes;
  @Input() endpoints;
  @Input() base;
  public endpoint = null;
  public item = null;
  public dataList = [];
  public isNew = false;
  public search = "";

  ngOnInit() {

  }

  filterList(datalist) {
    return _.filter(datalist, (data) => {
      let label = this.getLabel(data);
      return new RegExp(this.search).test(label) || new RegExp(this.search).test(data.id);
    })
  }

  save() {
    let output = this.editor.getValue();
    const errors = this.editor.validate();
    if (errors.length) {
      console.log("PROBLEM", errors)
      return;
    }
    if(this.isNew) {
      this.http.post<any>(this.base + this.endpoint.value.split("?")[0].replace(/-/g, "_"), output).subscribe((data) => {
        this.snackBar.open('Uloženo', 'OK', {
          duration: 3000
        });
        this.selectEndpoint(this.endpoint);
      },
      (err) => {
        this.snackBar.open('Chyba při ukládání: ' + err, 'OK');
      });
    } else {
      this.http.patch<any>(this.base + this.endpoint.value.split("?")[0].replace(/-/g, "_") + "/" + this.item, output).subscribe((data) => {
        this.snackBar.open('Uloženo', 'OK', {
          duration: 3000
        });
      },
      (err) => {
        this.snackBar.open('Chyba při ukládání: ' + err, 'OK');
      });
    }
  }

  selectEndpoint(endpoint) {
    this.isNew = false;
    this.item = null;
    this.endpoint = endpoint;
    this.getEndpointData();
  }

  selectItem(item) {
    this.item = item;
    this.isNew = false;
    this.http.get<any>(this.base + this.endpoint.value.split("?")[0].replace(/-/g, "_") + "/" + item).subscribe((data) => {
      this.initEditor(data);
    })
  }

  newItem() {
    this.item = null;
    this.isNew = true;
    setTimeout(() => {
      this.initEditor();
    }, 1);
  }

  initEditor(value?) {
    this.element = document.getElementById('editor-holder');
    JSONEditor.defaults.callbacks.template = {
      "applyFilterOption": (jseditor,e) => {
        return e.item.filter ? e.item.value : "";
      }
    }
    JSONEditor.defaults.callbacks.autocomplete = {
        "search_phrase_part": function search(jseditor_editor, input) {
          let lastEndI = input.lastIndexOf("}");
          let lastBeginI = input.lastIndexOf("{");
          if(lastBeginI <= lastEndI || lastBeginI + 1 == input.length) {
            return new Promise(function (resolve) {
              return resolve([]);
            });
          }
          let old = input.substring(0, lastBeginI);
          input = input.substring(lastBeginI + 1);
          let url = 'https://czechme-api.evetech.cz/app_content?id_like=' + encodeURI(input);
          return new Promise(function (resolve) {
              if (input.length < 2) {
                return resolve([]);
              }

              fetch(url).then(function (response) {
                return response.json();
              }).then(function (data) {
                  for(let i in data) {
                    data[i].previous = old;
                  }
                  resolve(data);
              });
          });
        },
        "search_lessons": function search(jseditor_editor, input) {
            let url = 'https://czechme-api.evetech.cz/app_lessons?id_like=' + encodeURI(input);
            return new Promise(function (resolve) {
                if (input.length < 2) {
                    return resolve([]);
                }
                fetch(url).then(function (response) {
                    return response.json();
                }).then(function (data) {
                    resolve(data);
                });
            });
        },
        "search_tasks": function search(jseditor_editor, input) {
          let url = 'https://czechme-api.evetech.cz/app_tasks?id_like=' + encodeURI(input);
          return new Promise(function (resolve) {
              if (input.length < 2) {
                  return resolve([]);
              }
              fetch(url).then(function (response) {
                  return response.json();
              }).then(function (data) {
                  resolve(data);
              });
          });
      },
        "search_content": function search(jseditor_editor, input) {
            let url = 'https://czechme-api.evetech.cz/app_content?id_like=' + encodeURI(input);
            if(input.startsWith("L")) {
              url = 'https://czechme-api.evetech.cz/app_locations?id_like=' + encodeURI(input);
            } 
            return new Promise(function (resolve) {
                if (input.length < 2) {
                    return resolve([]);
                }

                fetch(url).then(function (response) {
                    return response.json();
                }).then(function (data) {
                    resolve(data);
                });
            });
        },
        "renderResult_lessons": (jseditor_editor, result, props) => {
          return ['<li ' + props + '>',
                '<div>' + result["id"] + " " + (result.title ? result.title["cz"] : "") + '</div>',
                '</li>'].join(' ');
        },
        "renderResult_tasks": (jseditor_editor, result, props) => {
          return ['<li ' + props + '>',
                '<div>' + result["id"] + " " + result["type"] + '</div>',
                '</li>'].join(' ');
        },
        "renderResult_content": (jseditor_editor, result, props) => {
          return ['<li ' + props + '>',
                '<div>' + result.id + " " + (result.form ? result.form + " " : "") + (result.code ? result.code + " " : "") + (result.name ? result.name + " " : "") + (result.title ? result.title.cz + " " : "") + (result.transcription ? result.transcription + " " : "") + '</div>',
                '<div>' + (result.translation ? result.translation + " " : "") + (result.title ? result.title.en : "") + '</div>',
                '<div>' + (result.grammar ? "(" + _.join(_.keys(result.grammar), ", ") + ") " : "") + (result.content ? result.content.cz + " " : "") + (result.parts ? _.join(_.map(_.values(result.parts), (part) => part.transcript ), " "): "") + '</div>',
                '</li>'].join(' ');
        },
        "getResultValue_cid": function getResultValue(jseditor_editor, result) {
            return result.previous ? result.previous + "{" + result.id + "}" : result.id;
        }
    };
    console.log(this.addGlobals(this.endpointSchemes[this.endpoint.value]))
    this.editor  = new JSONEditor(this.element, {
      theme: "spectre",
      iconlib: "spectre",
      ajax: true,
      disable_edit_json: true,
      disable_collapse: true,
      disable_properties: false,
      disable_array_delete_all_rows: true,
      no_additional_properties: true,
      disable_array_delete_last_row: true,
      schema: this.addGlobals(this.endpointSchemes[this.endpoint.value])
    });
    if(value) {
      setTimeout(() => {
        this.editor.setValue(value);
      }, 500);
    }
  }

  getLabel(data) {
    return data.form ? data.form : (data.translation ? data.translation.en : (data.name ? data.name : (data.title ? (data.title.cz ? data.title.cz : data.title) : data.id)));
  }
  addGlobals(scheme) {
    return JSON.parse(JSON.stringify(scheme).replace(/\{\{SERVER_URL\}\}/g, environment.server));
  }

  getEndpointData() {
    this.http.get<any>(this.base + this.endpoint.value.replace(/-/g, "_")).subscribe((data) => {
      this.dataList = _.sortBy(data, this.getLabel);
    })
  }

  back() {
    if(this.item || this.isNew) {
      this.selectEndpoint(this.endpoint)
    } else{
      this.endpoint = null;
    }
  }

  getItemTitle(id: string): string {
    let item = _.find(this.dataList, ['id', id]);
    return this.getLabel(item);
  }

  delete(item) {
    let del = confirm("Opravdu chcete smazat tato data?");
    if(!del) {
      return;
    }
    this.http.delete<any>(this.base + this.endpoint.value.split("?")[0].replace(/-/g, "_") + "/" + item).subscribe((data) => {
      this.selectEndpoint(this.endpoint);
      this.snackBar.open('Smazáno', 'OK', {
        duration: 3000
      });
    },
    (err) => {
      this.snackBar.open('Chyba při mazání: ' + err, 'OK');
    })
  }

}
