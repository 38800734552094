import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataManagerComponent } from './data-manager/data-manager.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [DataManagerComponent],
  exports: [DataManagerComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatSnackBarModule,
    NgxPaginationModule
  ]
})
export class SharedModule { }
