<div>
    <ul class="breadcrumb">
        <li class="breadcrumb-item">
        <a (click)="back();back();">Datové sady</a>
        </li>
        <li *ngIf="endpoint" class="breadcrumb-item">
        <a (click)="isNew || item ? back(): null;">{{endpoint.title}}</a>
        </li>
        <li *ngIf="isNew || item" class="breadcrumb-item">
        <a (click)="null" >{{isNew ? "Nová položka" : getItemTitle(item)}}</a>
        </li>
    </ul>


    <div *ngIf="endpoint; else endpointsList">
        <div *ngIf="isNew || item; else itemsList">
            <div id="editor-holder"></div>
            <button class="btn btn-primary" (click)="save()"><i class="icon icon-check"></i> ULOŽIT</button>
        </div>
    </div>

    <ng-template #endpointsList>
        <table class="table">
            <thead>
                <tr>
                    <th>Datové sady</th>
                </tr>
            </thead>
            <tbody>
                <tr class="collection endpoints" (click)="selectEndpoint(endpoint)" *ngFor="let endpoint of endpoints">
                    <td><a role="button" tabindex="0">{{endpoint.title}}</a></td>
                </tr>
            </tbody>
        </table>

    </ng-template>

    <ng-template #itemsList>
        <table class="table">
            <thead>
                <tr>
                    <th>{{endpoint.title}}</th><th></th>
                </tr>
                <tr>Vyhledávání: <input type="text" [(ngModel)]="search" /></tr>
            </thead>
            <tbody>
                <tr class="collection items" (click)="selectItem(data.id)" *ngFor="let data of filterList(dataList) | paginate:{itemsPerPage: 10, currentPage:p}">
                    <td><a role="button" tabindex="0">{{getLabel(data)}}</a></td>
                    <td><button class="btn btn-primary" (click)="delete(data.id);$event.stopPropagation()"><i class="icon icon-delete"></i></button></td>
                </tr>
            </tbody>
        </table>     
        <pagination-controls (pageChange)="p=$event"></pagination-controls>

        <div class="collection items" (click)="newItem()">
            <button class="btn btn-primary" ><i class="icon icon-plus"></i> {{endpoint.title}}</button>
        </div>
    </ng-template>
</div>