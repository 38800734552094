import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import {CookieService} from 'ngx-cookie-service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private http: HttpClient, private cookie: CookieService) {}
  
  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    let path = route.params['module'];
    if(!route.params["module"]) {   
      path = "czechme";
    }
    return this.http.get('/assets/data/'+path+'/data-description.json').pipe(
      map((data) => {
        let pass;
        if(!this.cookie.check('pass-' + path)) {
          pass = prompt("Zadejte heslo:")
        } else {
          pass = this.cookie.get('pass-' + path);
        }
        if (CryptoJS.SHA256(pass).toString() != data['password']) {
          this.router.navigate([]);
          return false;
        }
        this.cookie.set('pass-' + path, pass);
        return true;
      }),
      catchError(val => {
        this.router.navigate([]);
        return of(false)
      })
    );
  }
}